/* Global Style */
* {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: 'Andika New Basic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
a,a:link, a:visited, a:active {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pointer {
  cursor: pointer;
}
.content {
  display: grid;
  /* background-color: red; */
  min-height: 100vh;
  width: 100%;
  background-color: #5D8BF4;
}
/* navbar class */
.navbar { 
  background-color: rgba(190, 199, 230, 0.5);
  column-gap: 10px;
  width: 99%;
  position: fixed;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1;
  padding: 0px 10px;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  box-sizing: border-box;
}
.navbar img {
  height: 90px;
}
.navbar ul {
  column-gap: 4px;
  /* display: grid; */
  /* justify-items: end; */
  justify-self: end;
  /* align-self: center; */
  display: grid;
  place-content: center;
  grid-template-columns: repeat(3, minmax(auto, 200px));
  /* justify-content: end; */
}
.home {
  display: grid;
  padding: 0px 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 100px 0px 25px;
}
.aboutus {
  margin-top: 100px; 
}
.portfolio {
  display: grid;
  margin-top: 100px; 
  gap: 20px;
}
/* carousel */
.carousel {
  /* background-color: blue; */
  
  display: grid;
  justify-content: center;
  width: 100%;
}
.carousel img {
  box-shadow: 0px 0px 4px 5px rgb(95, 154, 248);
  width: 100%;
  height: 400px;
  justify-self: center;
  box-sizing: border-box;
}
.swOn {
  animation: fadeIn 2s;
}
.swOff {
  animation: fadeOut 1.5s;
}
@keyframes fadeOut {
  0%   { opacity:1; }
  100% { opacity:0; }
}
@keyframes fadeIn {
  0%   { opacity:0; }
  100% { opacity:1; }
}
/* about section */
.about {
  margin-top: 50px;
  display: grid;
  gap: 60px;
  /* background-image: url(); */
  
}
.about> h1 {
  justify-self: center;
}
.aboutDetail {
  display: grid;
  justify-content: center;
  grid-template-columns: 300px 300px;
  /* grid-template-columns: repeat(2, minmax(180px, auto)); */
  column-gap: 20px;
}
.aboutDetail img {
  width: 100%;
}
.aboutDetail div h2, .aboutDetail div p {
  margin-bottom: 10px;
  font-family: cursive;
}
.aboutDetail div span {
  font-size: 25px;
}
/* aboutus class */
.visimisi {
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  justify-content: center;
  color: white;
  gap: 15px;
}
.visi, .misi {
  padding: 15px 30px;
  border-radius: 20px;
  box-shadow: 0px 3px 2px 5px rgb(0, 0, 0);
}
.visi {
  display: grid;
  grid-template-columns: repeat(2, auto); 
  gap: 10px;
  background-color: rgba(0, 0, 0, .87);

}
.misi {
  background-color: #1b85d1;
}
.visi h2, .visi p, .misi h2, .misi p {
  font-family: cursive;
}
.visimisi div span {
  font-size: 25px;
}
.nilaiwrap {
  display: grid;
  margin: 20px 0px;
}
.nilainilai {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 20px;
  padding: 10px 20px;
}
.nilainilai div {
  padding: 10px 20px;
  box-shadow: 0px 3px 2px 5px rgba(0, 0, 0, .6);
  border-radius: 10px;
  font-family: fantasy;
  font-size: 20px;
  /* background-color: #96CEB4; */
}
.nilainilai span {
  font-weight: bold;
}
.nilaiwrap h2 {
  justify-self: center;
}
/* sertifikat class */
.sertifikat {
  display: grid;
  row-gap: 20px;
}
.sertifikat h3 {
  justify-self: center;
}
.sertList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, 300px);
  place-content: center;
}
.sertList embed{
  height: 470px;
}
/* portofolio class */
.portfolio li {
  padding: 0px 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  /* align-items: center; */
}
.portfolio li img {
  width: 100%;
}
/* footer class */
.footer {
  /* justify-self: center; */
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .87);
  min-height: 120px;
  color: white;
  display: grid;
  padding: 20px 5% 0px;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.footer h3 {
  color: blue;
}
.footer p {
  font-size: 15px;
}
.footer img {
  width: 130px;
}
@media (max-width: 600px) {
  .navbar img {
    height: 80px;
    width: 150px;
  }
  .navbar {
    font-size: small;
  }
  .aboutDetail {
    grid-template-columns: 150px 200px;
  }
}